import React from 'react';
import styled from 'styled-components';
import { Navbar } from '../components/navbar';
import Background from '../assets/pictures/bg.jpg';
import { MoreAboutSection } from './homepage/moreAboutSection';

const Header = styled.header`
  width: 100%;
  height: 28rem;
  background: url(${Background}) repeat 0 0;
  display: block;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .header-title {
    width: 100%;
    background-color: rgba(73, 67, 149, 1);
    color: white;
    padding: 0;

    h1 {
      font-size: 2em;
      font-weight: 600;
      margin-left: 20rem;
    }
  }
  @media (max-width: 46.9em) {
    .header-title {
      padding: 0.5em 0 0 0;
      font-size: 0.5em;
      h1 {
        margin-left: 1rem;
      }
    }
  }
`;

const Container = styled.div`
  position: relative;

  h1,
  h2 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
  }

  h2 {
    font-size: 10px;
  }
  @media (max-width: 46.9em) {
    section {
      margin: 0.2rem 0.5rem;
      padding: 0 0.5em 1em 0.5em;
    }

    h1 {
      font-size: 1em;
      font-weight: bold;
    }

    h2 {
      margin: 0.2em 0 0 1em;
      padding: 0.1em;
      font-size: 0.875em;
    }
  }

  @media (min-width: 47em) {
    section {
      margin: 0.5rem 10rem;
      padding: 0.3rem 10rem;
    }

    h2 {
      /* margin: 0.8em 0 0 7%; */
      padding: 0.2em;
      font-size: 1.2em;
    }

    h1 {
      font-size: 1.875em;
      font-weight: 600;
    }
  }
`;

const Content = styled.div`
  /* padding-top: 10rem; */
`;

const NavbarContainer = styled.div`
  position: fixed;
  width: 100%;
  background: rgba(73, 67, 149, 0.7);
  height: 7rem;
  @media (max-width: 46.9em) {
    height: 5rem;
  }
`;

const TermsAndConditions = () => {
  return (
    <Container>
      <NavbarContainer>
        <Navbar />
      </NavbarContainer>
      <Content>
        <Header>
          <div className='header-title'>
            <h1>Terms and Conditions</h1>
          </div>
        </Header>
        <article>
          <section>
            <h1 id='personal-info'>Definitions </h1>
            <h2 id='misc_children'>
              1.1. “The Company” means Raino Tech4Impact, Markiti and its
              affiliates.
            </h2>
            <h2 id='misc_children'>
              1.2. “The Customer” means any person, firm, company, or any other
              legal entity which places an order, or buys any Products from the
              Company.
            </h2>
            <h2 id='misc_children'>
              1.3. “Products” means any food and/ or equipment i.e. cooler box
              agreed in the contract to be supplied by the Company to the
              Customer.
            </h2>
          </section>

          <section>
            <h1 id='collection'>Orders</h1>

            <h2 id='collection_tracking'>
              2.1. The Customer is responsible for ensuring that the terms of
              any order are complete and accurate.
            </h2>
            <h2 id='collection_tracking'>
              2.2. No pricing made available to the Customer in any way shall
              constitute an offer and the Company may amend its prices at any
              time. Prices are quoted exclusive of VAT which shall be charged if
              applicable.
            </h2>
            <h2 id='collection_tracking'>
              2.3. The risk of loss or damage to the Products shall pass to the
              Customer on completion of delivery.
            </h2>
            <h2 id='collection_tracking'>
              2.4. An order may not be cancelled by the Customer without the
              written consent of the Company.
            </h2>
          </section>

          <section>
            <h1 id='use'>Delivery</h1>
            <h2>
              3.1. Deliveries of Products to the Customer shall be in accordance
              with the Company’s delivery schedule.
            </h2>
            <h2>
              3.2. Delivery of Products shall be made by the Company to a
              reasonably accessible location at the Customer’s premises, or as
              otherwise agreed between the parties (“the Delivery Location”) at
              a small fee as per the Company rates.
            </h2>
          </section>

          <section>
            <h1 id='disclosure'>Payment</h1>

            <h2 id='disclosure_partners'>
              4.1. Payments should be made upon completion of the delivery
              process.
            </h2>
            <h2 id='disclosure_partners'>
              4.2. Failure to pay by the due date shall entitle the Company to
              suspend delivery of all unexecuted or future orders.
            </h2>
          </section>

          <section>
            <h1 id='storage'>Return Policy</h1>

            <h2 id='storage_location'>
              5.1. Returns and exchanges with us are very easy and free! We are
              happy to refund or exchange Products only after:
              <ul>
                <li>
                  Examining the Product before signing its delivery note you
                  realize its damaged, rotten or not as described. If not
                  satisfied, hand it back to the delivery agent for return or
                  exchange.
                </li>
                <li>
                  Contacting us on 020 2400 740/0716888877 or
                  sales@markiti.co.ke/support@markiti.co.ke after which we will
                  send a delivery agent to your doorstep to initiate the return
                  or exchange.
                </li>
                <li>
                  Placing the item in its original packaging, including any
                  tags, labels or freebies and give it back to the delivery
                  agent.
                </li>
              </ul>
            </h2>
            <h2 id='disclosure_partners'>
              5.2. Once we have received your returned Product, we will inspect
              it and process your refund via MPESA or bank transfer. Transfers
              take 24 hours after initiation.
            </h2>
          </section>
        </article>
      </Content>
      <MoreAboutSection />
    </Container>
  );
};

export default TermsAndConditions;
