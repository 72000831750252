import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import { Button } from '../../components/button';
import { useCart } from '../../subjects/CartContext';
import { theme } from '../../theme';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
const CardContainer = styled.div`
  width: 18rem;
  height: 28rem;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : 'rgba(73, 67, 149)'};
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
  overflow-x: hidden;
  &:hover {
    overflow: auto;
    img {
      opacity: 0.3;
    }
    .middle {
      opacity: 1;
    }
  }
  @media screen and (max-width: 670px) {
    width: ${props => (props.small ? '18rem' : '18rem')};
    height: 28rem;
  }
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 1em;

  span {
    color: white;
    font-style: normal;
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  padding: 0 1rem;
  span {
    color: white;
    font-style: normal;
    font-weight: bold;
  }
  Button {
    border-radius: 0.5rem;
    /* width: 100%; */
    margin-top: 0.5rem;
    padding: 0.5rem 2rem;
    background-color: #fff;
    color: black;
    border: 1px solid #494395;
    &:hover {
      background-color: #494395;
      color: white;
    }
  }
`;

const Title = styled.h2`
  font-size: 1rem;
  padding: 0;
  margin: 0;
  font-weight: bold;
  color: black;
  margin-bottom: 0.5rem;
`;

const SubTitle = styled.h2`
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: gray;
  margin-bottom: 1rem;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
  background-color: whitesmoke;
  border-radius: 0.5rem;
  p {
    margin: 0 2rem;
    font-weight: bold;
  }
`;

const IconContainer = styled.span`
  border: 1px solid #494395;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 220ms ease-in-out;
  svg {
    color: #494395;
  }
  &:hover {
    cursor: pointer;
    background-color: ${theme.primary};
    border: 1px solid ${theme.primary};
    svg {
      color: white;
    }
  }
`;

const ProductImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  image-resolution: from-image 300dpi;
  transition: 0.5s ease-in-out;
`;

const Middle = styled.div`
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  color: #494395;
  font-weight: bold;
`;

const Container = styled.div`
  position: relative;
  z-index: 999,
  width: 50%;
  &:hover: {
    img {
      opacity: 0.2;
    }

     {
      opacity: 1;
    }
  }
`;

export function ProductCard(props) {
  const [value, setValue] = useState(0);
  const {
    title,
    imgUrl,
    backgroundColor,
    small,
    price,
    product,
    itemInCart,
    description
  } = props;
  const { addItemToCart } = useCart();

  useEffect(() => {
    if (itemInCart) {
      setValue(itemInCart.qty);
    }
  }, [itemInCart]);

  const handleIncrement = index => {
    setValue(prev => prev + 1);
  };

  const handleDecrement = index => {
    if (value > 0) {
      setValue(prev => prev - 1);
    }
  };

  return (
    // <Tippy placement='top' content={description ? description : title}>
    <CardContainer small={small} backgroundColor={backgroundColor}>
      <Container style={{ height: '50%', width: '100%' }}>
        <ProductImg src={imgUrl} />
        <Middle className='middle'>{description ? description : title}</Middle>
      </Container>
      <UserDetails>
        <Title>{title}</Title>
        <SubTitle>Ksh. {price}</SubTitle>
      </UserDetails>
      <ButtonContainer>
        <ActionContainer>
          <IconContainer onClick={handleDecrement}>
            <FaMinus size={12} />
          </IconContainer>
          <p>{value}</p>

          <IconContainer onClick={handleIncrement}>
            <FaPlus size={12} />
          </IconContainer>
        </ActionContainer>
        <Button onClick={() => addItemToCart(product, value ? value : 1)}>
          Add to basket
        </Button>
      </ButtonContainer>
    </CardContainer>
    // </Tippy>
  );
}
