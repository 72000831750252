import React, { createContext, useContext, useState } from 'react';

import { toast } from 'react-toastify';

export const CartContext = createContext({});

export function CartProvider(props) {
  const [items, setItems] = useState([]);
  function addItemToCart(product, qty) {
    setItems(prevItems => {
      const item = prevItems.find(item => item.product.id == product.id);
      if (!item) {
        return [
          ...prevItems,
          {
            qty: qty ? qty : 1,
            product,
            totalPrice: product.unit_value * qty
          }
        ];
      } else {
        return prevItems.map(item => {
          if (item.product.id == product.id) {
            item.qty = qty;
            item.totalPrice = product.unit_value * qty;
          }
          return item;
        });
      }
    });
    toast.success('Item added to basket');
  }

  function removeItem(id) {
    return setItems(() => items?.filter(item => item?.product.id !== id));
  }

  function updateCartItem(updateditems) {
    setItems(() => updateditems);
  }
  function getItemsCount() {
    return items.length;
  }

  function getTotalPrice() {
    return items.reduce((sum, item) => sum + item.totalPrice, 0);
  }

  return (
    <CartContext.Provider
      value={{
        items,
        setItems,
        getItemsCount,
        addItemToCart,
        getTotalPrice,
        updateCartItem,
        removeItem
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}

export function useCart() {
  return useContext(CartContext);
}
