import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Element, scroller } from 'react-scroll';
import { slide as Menu } from 'react-burger-menu';
import { NavbarSubject } from '../../subjects/NavbarSubject';
import { Logo } from '../logo';
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn
} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
const NavbarContainer = styled.div`
  width: 100%;
  height: 100px;
  padding: 0 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  color: #fff;
`;

const BrandContainer = styled.div`
  cursor: pointer;
`;

const BurgerMenu = styled(Menu)`
  width: 25% !important;
  height: 100%;
  position: fixed;
  .bm-menu {
    z-index: 10;
    background: rgb(38, 164, 162, 0.8);
    height: 100%;
    padding: 2.5em 1.5em 0;
    display: flex;
    flex-direction: column;
  }
  .bm-cross {
    background: #fff;
  }
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  .bm-item-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 5rem;
  }
  .menu-item {
    color: #fff;
    font-size: 24px;
    font-weight: normal;
    padding: 0;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 1rem 0.75rem;
    cursor: pointer;
    &:hover {
      background: rgba(73, 67, 149, 0.9);
    }
  }
  @media screen and (max-width: 670px) {
    width: 100% !important;
    height: 100% !important;
    .menu-item {
      font-size: 18px;
    }
  }
`;

var styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    right: '36px',
    top: '36px',
    zIndex: 1000
  },
  bmBurgerBars: {
    background: '#fff'
  }
};

const IconsContainer = styled.div`
  justify-self: end;
  margin-top: auto;
  margin-bottom: 2rem;
  .links {
    color: white;
    font-size: 1rem;
  }
  div {
    display: flex;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid white;
      height: 2em;
      width: 2em;
      margin-right: 1rem;
    }
  }
  p {
    cursor: pointer;
  }
`;

const PlaceOrderIcon = styled.h4`
  font-style: capitalize;
  margin-right: 5rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  cursor: pointer;
  @media screen and (max-width: 670px) {
    display: none;
  }
`;

export function Navbar(props) {
  const [iconColor, setIconColor] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const color = NavbarSubject.subscribe(payload => {
      setIconColor(payload.color);
    });
    return () => {};
  }, []);

  const history = useHistory();

  const scrollToHomeSection = () => {
    scroller.scrollTo('home', { smooth: true, duration: 1500 });
  };

  const scrollToBuySection = () => {
    scroller.scrollTo('buy', { smooth: true, duration: 1500 });
  };

  const scrollToWhosaleSection = () => {
    scroller.scrollTo('wholesale', { smooth: true, duration: 1500 });
  };

  const scrollToContactSection = () => {
    scroller.scrollTo('contact', { smooth: true, duration: 1500 });
  };

  return (
    <>
      <BurgerMenu
        styles={styles}
        noOverlay
        outerContainerId={'outer-container'}
        right
      >
        <div>
          <p id='home' onClick={scrollToHomeSection} className='menu-item'>
            HOME
          </p>
          <p
            id='buyfish'
            onClick={() => history.push('/products')}
            className='menu-item'
          >
            PLACE ORDER
          </p>
          <p
            id='wholesale-support'
            onClick={scrollToWhosaleSection}
            className='menu-item'
          >
            WHOLESALE SUPPORT
          </p>
          <p onClick={scrollToContactSection} className='menu-item'>
            CONTACT
          </p>
        </div>
        <IconsContainer>
          <p className='links' onClick={() => history.push('/privacy-policy')}>
            Policies
          </p>
          <p
            className='links'
            onClick={() => history.push('/terms-and-conditions')}
          >
            Terms and Conditions
          </p>
          <div>
            <a
              href='https://m.facebook.com/markitiapp/?ref=bookmarks'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FaFacebookF color='#fff' />
            </a>
            <a
              href='http://instagram.com/markitiapp/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FaInstagram color='#fff' />
            </a>
            <a href='/' target='_blank' rel='noopener noreferrer'>
              <FaLinkedinIn color='#fff' />
            </a>
            <a
              href='https://twitter.com/Markitiapp?s=09'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FaTwitter color='#fff' />
            </a>
          </div>
        </IconsContainer>
      </BurgerMenu>
      <NavbarContainer>
        <BrandContainer onClick={() => history.push('/')}>
          <Logo inline />
        </BrandContainer>
        <PlaceOrderIcon onClick={() => history.push('/products')}>
          Place orders
        </PlaceOrderIcon>
      </NavbarContainer>
    </>
  );
}
