import React from 'react';
import styled from 'styled-components';

export const FormGroup = styled.div`
  color: palevioletred;
  display: block;
`;

export const FormGroupCheckbox = styled.div`
  color: palevioletred;
  display: block;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.label`
  margin-bottom: 0.5em;
  color: #787878;
  display: block;
  align-items: center;
`;
export const CheckBox = styled.input`
  color: #787878;
  background: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 0.5em;
  border: 1px solid #787878;
  outline: none;
  font-weight: bold;
`;

export const Input = styled.input`
  padding: 1rem 0.5em;
  color: #787878;
  background: white;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 0.5em;
  border: 1px solid #787878;
  outline: none;
  font-weight: bold;
`;

export const SelectInput = styled.select`
  padding: 1rem 0.5em;
  color: #787878;
  background: white;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 0.5em;
  border: 1px solid #787878;
  outline: none;
  font-weight: bold;
`;

export const TextArea = styled.textarea`
  padding: 1rem 0.5em;
  color: #787878;
  background: white;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 0.5em;
  border: 1px solid #787878;
  outline: none;
  font-weight: bold;
  height: 8rem;
`;

export const Message = styled.label`
  margin-bottom: 0.5em;
  color: red;
  display: block;
`;
