import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

const ButtonWrapper = styled.button`
  padding: ${({ small }) => (small ? '5px 8px' : '15px 15px')};
  border-radius: 1px;
  background-color: rgba(73, 67, 149);
  color: #fff;
  font-weight: bold;
  font-size: ${({ small }) => (small ? '12px' : '16px')};
  outline: none;
  border: 2px solid transparent;
  transition: all 220ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${theme.primary};
  }
`;

export function Button(props) {
  return <ButtonWrapper {...props}>{props.children}</ButtonWrapper>;
}
