import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

const ArrowContainer = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  transition: all 250ms ease-in-out;
  border: 8px solid #ccc;
  cursor: pointer;

  &:hover {
    border: 5px solid ${theme.primary};
  }
`;

const ArrowIcon = styled.div`
  margin-top: 3px;
  color: #2ab4aa;
  font-size: 29px;
  &:hover {
    color: rgba(73, 67, 149);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h4 {
    color: white;
    font-weight: normal;
    font-size: 18px;
    padding: 0;
    margin: 0.857em 0;
  }
`;

export function DownArrow() {
  return (
    <Container>
      <h4>Order Now</h4>
      <ArrowContainer>
        <ArrowIcon>
          <FontAwesomeIcon icon={faAngleDown} />
        </ArrowIcon>
      </ArrowContainer>
    </Container>
  );
}
