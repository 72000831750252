import React from "react";
import styled, { css } from "styled-components";
import { ProductCard } from "../../components/productCard";
import { useProduce } from "../../hooks/useProducts";
import {
  FaCartPlus,
  FaCross,
  FaShoppingBasket,
  FaTrash,
  FaWindowClose
} from "react-icons/fa";
import Logo from "../../assets/logo/logo2.png";
import { slide as Menu } from "react-burger-menu";
import { useState } from "react";
import { Button } from "../../components/button";
import { useCart } from "../../subjects/CartContext";
import { thousands_separators } from "../../utils/formatCurrency";
import {
  CheckBox,
  FormGroup,
  FormGroupCheckbox,
  Input,
  Label,
  Message,
  SelectInput,
  TextArea
} from "../../components/forms/Forms";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useEffect } from "react";
import { formatPhoneNumber } from "../../utils/formatPhonenumber";
import { Wizard, useWizard } from "react-use-wizard";
import { BASE_API_URL } from "../../utils/app-constants";

const UserDetails = ({ setOrder, setOrderNumber, items }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm();

  const handlePlaceOrder = data => {
    return axios.post(`${BASE_API_URL}public/order`, data);
  };

  const { mutate, isLoading, isSuccess } = useMutation(handlePlaceOrder, {
    onSuccess: results => {
      const { returned_resultset } = results.data;
      setOrderNumber(returned_resultset[0]?.order_number);
      setOrder(returned_resultset[0]);
      toast.success(
        "Order placed succesfully, Kindly make payment using the instructions below"
      );
      nextStep();
    },
    onError: error => {
      toast.error(
        `${error?.response?.data?.message} Unable to complete your request please try again`
      );
    }
  });

  const onSubmit = data => {
    const cartItems = items?.map(item => {
      return {
        product_id: item.product.id,
        quantity: item.qty
      };
    });
    let clonedData = { ...data };
    delete clonedData.date;
    delete clonedData.is_company;
    if (cartItems.length) {
      mutate({
        ...clonedData,
        phone_number: formatPhoneNumber(data?.phone_number),
        cart_items: cartItems,
        delivery_time: `${data?.date}, ${data?.delivery_time}`
      });
    } else {
      toast.error("No items in basket");
    }
  };

  const today = new Date();

  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  const isCompany = watch("is_company");

  const isSunday = dateString => {
    const selectedDate = new Date(dateString);
    return selectedDate.getDay() === 0; // 0 represents Sunday
  };

  return (
    <Form>
      <FormActions>
        <h3>Step 1</h3>
      </FormActions>
      <FormActions>
        <strong>Enter your details</strong>
      </FormActions>
      <FormGroup style={{ flexDirection: "row", display: "flex" }}>
        <FormGroupCheckbox style={{ marginRight: "1rem" }}>
          <Label>Business</Label>
          <CheckBox
            type="radio"
            {...register("is_company", {
              required: "Type is required"
            })}
            value={true}
            name="is_company"
          />
        </FormGroupCheckbox>
        <FormGroupCheckbox>
          <Label>Individual</Label>
          <CheckBox
            type="radio"
            {...register("is_company", {
              required: "Type is required"
            })}
            name="is_company"
            value={false}
            defaultChecked
          />
        </FormGroupCheckbox>
      </FormGroup>
      {isCompany && isCompany === "true" ? (
        <FormGroup>
          <Label>
            Business Name <span style={{ color: "red" }}>*</span>
          </Label>
          <Input
            name="names"
            {...register("names", {
              required: "Business name is required"
            })}
          />
          <Message>{errors?.names?.message}</Message>
        </FormGroup>
      ) : (
        <FormGroup>
          <Label>
            Full Name <span style={{ color: "red" }}>*</span>
          </Label>
          <Input
            name="first_name"
            {...register("first_name", {
              required: "Name is required"
            })}
          />
          <Message>{errors?.first_name?.message}</Message>
        </FormGroup>
      )}
      <FormGroup>
        <Label>
          Phone Number <span style={{ color: "red" }}>*</span>
        </Label>
        <Input
          placeholder="Enter your number as 07XX XXX XXX"
          name="phone_number"
          {...register("phone_number", {
            required: "Phone Number is required"
            // pattern: {
            //   value: /^0(7(?:(?:[129][0-9])|(?:0[0-8])|(4[0-1]))[0-9]{6})$/i,
            //   message: 'Enter a valid phone_number'
            // }
          })}
        />
        <Message>{errors?.phone_number?.message}</Message>
      </FormGroup>

      <FormGroup>
        <Label>
          Location <span style={{ color: "red" }}>*</span>
        </Label>
        <Input
          name="address"
          {...register("address", {
            required: "Please add a location"
          })}
        />
        <Message>{errors?.address?.message}</Message>
      </FormGroup>
      <FormGroup>
        <Label>
          Building <span style={{ color: "red" }}>*</span>
        </Label>
        <Input
          placeholder="Nearest landmark e.g school"
          name="building"
          {...register("building", {
            required: "Building name is required"
          })}
        />
        <Message>{errors?.building?.message}</Message>
      </FormGroup>

      <FormGroup>
        <Label>Notes</Label>
        <TextArea
          placeholder="Enter any instructions for your order"
          name="notes"
          {...register("notes")}
        />
      </FormGroup>
      <FormGroup>
        <p>Disclaimers:</p>
        <ul>
          <li>Please note that we only do next day delivery</li>
          <li>Delivery in Nairobi and its environs only</li>
          <li>We charge delivery of 400 KES</li>
          <li>Free delivery for orders above Ksh 4,000</li>
          <li>Please note you can use your name as the business name</li>
        </ul>
      </FormGroup>
      <FormGroup>
        <Label>
          Delivery date <span style={{ color: "red" }}>*</span>
        </Label>
        <Input
          type="date"
          placeholder="Nearest landmark e.g school"
          name="date"
          min={tomorrow.toISOString().split("T")[0]}
          {...register("date", {
            required: "Delivery name is required",
            validate: value => !isSunday(value) || "No deliveries on Sundays"
          })}
        />
        <Message>{errors?.date?.message}</Message>
      </FormGroup>
      <FormGroup>
        <Label>
          Preferred time of delivery <span style={{ color: "red" }}>*</span>
        </Label>
        <SelectInput
          placeholder="Nearest landmark e.g school"
          name="delivery_time"
          {...register("delivery_time")}
        >
          <option value="Late Morning">Late Morning</option>
          <option value="Afternoon">Afternoon</option>
          <option value="Evening">Evening</option>
        </SelectInput>
        <Message>{errors?.delivery_time?.message}</Message>
      </FormGroup>
      <FormGroupCheckbox>
        <Label>
          Allow promotional messages<span style={{ color: "red" }}>*</span>
        </Label>
        <CheckBox
          type="checkbox"
          name="notification_consent"
          {...register("notification_consent")}
        />
      </FormGroupCheckbox>

      <Message>{errors?.building?.message}</Message>
      <FormActions>
        <Button disabled={isLoading} onClick={handleSubmit(onSubmit)}>
          <Totals>
            {isLoading ? "Completing Order..." : "Complete Order"}
          </Totals>
        </Button>
      </FormActions>
    </Form>
  );
};

const Payment = ({ setItems, getTotalPrice, orderNumber, order }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const history = useHistory();
  const {
    register: stkRegister,
    handleSubmit: stkHandleSubmit,
    setValue
  } = useForm();

  const phoneNumber = order?.agent?.contact_phonenumber;
  useEffect(() => {
    if (order?.id && phoneNumber) setValue("stk_phone_number", phoneNumber);
  }, [phoneNumber, order, setValue]);

  const handleStkPush = data => {
    return axios.post(`${BASE_API_URL}public/order/${order.id}/stk`, data);
  };

  const { mutate: stkMutation, isLoading } = useMutation(handleStkPush, {
    onSuccess: () => {
      toast.success("Order successful");
      setItems([]);
      history.push("/products");
    },
    onError: error => {
      toast.error(
        error?.response?.data?.message ??
          "Payment request failed. Kindly pay using the paybill number"
      );
    }
  });

  const handlePaylaterPush = data => {
    return axios.put(`${BASE_API_URL}public/order/${order.id}`, data);
  };

  const { mutate } = useMutation(handlePaylaterPush, {
    onSuccess: () => {
      toast.success("Order successful");
      setItems([]);
      history.push("/products");
    },
    onError: error => {
      toast.error(
        error?.response?.data?.message ??
          "Payment request failed. Kindly pay using the paybill number"
      );
    }
  });

  const stkSubmit = data => {
    console.log(data, "data");
    const payload = {
      phonenumber: formatPhoneNumber(data.stk_phone_number),
      amount: getTotalPrice() > 4000 ? getTotalPrice() : getTotalPrice() + 400
    };
    stkMutation(payload);
  };

  return (
    <PaymentDetails>
      <FormActions>
        <h3>Step 2</h3>
      </FormActions>
      <FormActions>
        <strong>Complete Payment</strong>
      </FormActions>
      <p>Click on Pay to initiate the M-PESA payment</p>
      <p>Check your mobile for the M-PESA pin prompt</p>
      <p>Enter M-PESA pin to approve payment</p>
      <FormGroup>
        <Label>
          Please provide the phone number your would like to pay with (M-Pesa):
        </Label>
        <Input
          placeholder="Enter phone number for M-PESA payment"
          name="stk_phone_number"
          defaultValue={phoneNumber}
          {...stkRegister("stk_phone_number", {
            required: "Phone Number is required"
          })}
        />
      </FormGroup>
      <div style={{ display: "flex" }}>
        <NewButtonPrimary
          disabled={isLoading}
          onClick={stkHandleSubmit(stkSubmit)}
        >
          Pay Now
        </NewButtonPrimary>
        <NewButton
          style={{ marginLeft: "1rem" }}
          disabled={isLoading}
          onClick={() => {
            mutate({ payment_mode: "ondelivery" });
          }}
        >
          Pay on delivery
        </NewButton>
      </div>

      <FormActions>
        <strong>you can also pay directly to our M-PESA paybill number </strong>
      </FormActions>

      <p>Kindly make payment using the instructions below</p>
      <p>Select ‘Pay Bill’ from your M-PESA</p>
      <p>
        Enter the ‘Business Numbers: <span>4015545</span>
      </p>
      <p>
        Enter the ‘Account Number’: <span>{orderNumber}</span>{" "}
      </p>
      <p>
        Enter the amount:{" "}
        <span>
          {getTotalPrice() > 4000 ? getTotalPrice() : getTotalPrice() + 400}
        </span>{" "}
      </p>
      <p>Confirm that all the details are correct and press Ok</p>
      <Button
        disabled={isLoading}
        onClick={() => {
          toast.success("Order successful");
          setItems([]);
          history.push("/products");
        }}
      >
        <Totals>Confirm payment</Totals>
      </Button>
    </PaymentDetails>
  );
};

const Checkout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [order, setOrder] = useState();

  const { items, getTotalPrice, removeItem, getItemsCount, setItems } =
    useCart();

  return (
    <>
      <Container>
        <NavbarContainer>
          <LogoContainer onClick={() => null}>
            <LogoImg src={Logo} />
          </LogoContainer>
          <BrandContainer onClick={() => setIsOpen(true)}>
            <FaCartPlus size={48} color="#8B8B8B" />
            <span>{getItemsCount()}</span>
          </BrandContainer>
        </NavbarContainer>
        <Header>
          <Title>
            <span>Checkout</span>
            <p style={{ fontSize: "1.5rem" }}>Please complete step 1 & 2</p>
          </Title>
        </Header>
        <ProductsContainer>
          <Wizard>
            <UserDetails
              setOrder={setOrder}
              setOrderNumber={setOrderNumber}
              items={items}
            />
            <Payment
              setItems={setItems}
              getTotalPrice={getTotalPrice}
              orderNumber={orderNumber}
              order={order}
            />
          </Wizard>

          <ProductList>
            <div>
              <MenuHeader>
                <>
                  <FaShoppingBasket size={32} color="#747577" />
                  <p style={{ color: "#787878" }}>{getItemsCount()} items</p>
                </>
              </MenuHeader>
              {items?.map(item => {
                return (
                  <CartItem
                    key={item?.product.display_name}
                    className="menu-item"
                  >
                    <div>
                      <p style={{ fontWeight: "bold" }}>
                        {" "}
                        {item?.product.display_name}
                      </p>
                      <p>
                        {item.qty} * {item?.product.unit_value}
                      </p>

                      <p>
                        Ksh.{" "}
                        {thousands_separators(
                          item.qty * item?.product.unit_value
                        )}
                      </p>
                    </div>
                    {/* <FaTrash
                      color='#8B8B8B'
                      // onClick={() => removeItem(item.product?.id)}
                    /> */}
                  </CartItem>
                );
              })}
              {items?.length && getTotalPrice() < 4000 ? (
                <CartItem className="menu-item">
                  <div>
                    <p style={{ fontWeight: "bold" }}>Delivery Fee</p>
                    <p>Ksh. 400</p>
                  </div>
                </CartItem>
              ) : null}
              {items?.length ? (
                <CheckOutActions>
                  <Totals>
                    <span>
                      Order Total: Ksh.{" "}
                      {thousands_separators(
                        getTotalPrice() > 4000
                          ? getTotalPrice()
                          : getTotalPrice() + 400
                      )}
                    </span>
                  </Totals>
                </CheckOutActions>
              ) : null}
            </div>
          </ProductList>
        </ProductsContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: block;
  background-color: #f6f9fc;
  padding-bottom: 1rem;
  column-gap: 2rem;
`;

const Form = styled.form`
  background-color: white;
  display: flex;
  column-gap: 2rem;
  row-gap: 1rem;
  flex-direction: column;
  padding: 3rem;
  border-radius: 1rem;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  /* @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 1fr;
  }
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr;
  } */
`;

const FormActions = styled.div`
  grid-column: 1 / span 2;
  Button {
    border-radius: 0.5rem;
    /* width: 100%; */
    padding: 0 2rem;
    background-color: #fff;
    color: black;
    border: 1px solid #494395;
    &:hover {
      background-color: #494395;
      color: white;
    }
  }
  h3 {
    padding: 0;
    margin: 0;
  }
`;

const NewButton = styled(Button)`
  border-radius: 0.2rem !important;
  /* width: 100%; */
  padding: 0.4rem 2rem !important;
  background-color: #fff;
  color: black;
  border: 1px solid #494395;
  margin: 1rem 0rem;
  &:hover {
    background-color: #494395;
    color: white;
  }
`;

const NewButtonPrimary = styled(Button)`
  border-radius: 0.2rem !important;
  /* width: 100%; */
  padding: 0.4rem 2rem !important;
  background-color: #494395 !important;
  color: #fff !important;
  border: 1px solid #494395 !important;
  margin: 1rem 0rem;
  &:hover {
    border: 1px solid #2ab4aa !important;
    background-color: #2ab4aa !important;
    color: white;
  }
`;

const PaymentDetails = styled.div`
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: #fff;
  border-radius: 1rem;
  margin: 1rem 0;
  padding: 1rem;
  @media (min-width: 900px) {
    margin-top: 0;
  }
  @media (min-width: 600px) {
    margin-top: 0;
  }

  span {
    font-weight: bold;
  }
  Button {
    border-radius: 0.5rem;
    /* width: 100%; */
    padding: 0rem 2rem;
    background-color: #fff;
    color: black;
    border: 1px solid #494395;
    &:hover {
      background-color: #494395;
      color: white;
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding: 0 3.5rem;
  padding-top: 4.5rem;
  p {
    font-size: 1.5rem;
    font-weight: bold;
  }
  @media (min-width: 900px) {
    padding: 0 12rem;
    padding-top: 4.5rem;
  }
  @media (min-width: 600px) {
    padding: 0 12rem;
    padding-top: 4.5rem;
  }
`;

const Title = styled.h3`
  font-size: 2.5rem;
  padding: 0;
  margin: 1rem 0;
  span {
    color: #494395;
  }
  p {
    margin: 0.5rem 0;
  }
`;

const ProductsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 3rem;
  column-gap: 2rem;
  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    margin: 0 9rem;
  }
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    margin: 0 9rem;
  }
`;
const ProductList = styled.div`
  display: block;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin-top: 1rem;
  @media (min-width: 900px) {
    margin-top: 0;
  }
  @media (min-width: 600px) {
    margin-top: 0;
  }
`;
const NavbarContainer = styled.div`
  width: 100%;
  padding: 0.5rem 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  position: fixed;
  z-index: 10;
  color: black;
`;

const BrandContainer = styled.div`
  cursor: pointer;
  position: relative;
  span {
    background-color: red;
    color: white;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};
  align-items: center;
  @media screen and (max-width: 670px) {
    margin-top: 0.5rem;
  }
`;

const LogoImg = styled.img`
  width: 200px;
  height: 70px;

  @media screen and (max-width: 670px) {
    width: 100px;
    height: 40px;
  }

  ${({ inline }) =>
    inline &&
    css`
      width: 24px;
      height: 24px;
      margin-right: 6px;
    `};

  ${({ small }) =>
    small &&
    css`
      width: 8em;
      height: 8em;
    `};
`;

const MenuHeader = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  border-bottom: 1px solid #ccc;
  p {
    margin-left: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  border-bottom: 1px solid #ccc;
`;

const CheckOutActions = styled.div`
  display: flex;
  padding: 1em;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  Button {
    border-radius: 0.5rem;
    width: 100%;
    padding: 0;
  }
`;

const Totals = styled.p`
  font-weight: bold;
  width: 100%;
  padding: 0;
`;

export default Checkout;
