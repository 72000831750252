export const formatPhoneNumber = (phonenumber, hasSpaces) => {
  var cleaned = ('' + phonenumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\+254|0|254|^)?(\d{3})(\d{3})(\d{3})$/);

  if (match) {
    let intlCode = match[1] && hasSpaces ? '+254 ' : '254';
    return hasSpaces
      ? [intlCode, ' ', match[2], ' ', match[3], ' ', match[4]].join('')
      : [intlCode, match[2], match[3], match[4]].join('');
  }
  return '';
};
