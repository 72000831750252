import React from 'react';
import styled from 'styled-components';
import { Footer } from '../../components/footer';
import { Marginer } from '../../components/marginer';
import { MoreAboutSection } from './moreAboutSection';
import { ReviewsSection } from './reviewsSection';
import { ServicesSection } from './servicesSection';
import { TopSection } from './topSection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export function Homepage(props) {
  return (
    <PageContainer id='outer-container'>
      <TopSection />
      <ServicesSection />
      <ReviewsSection />
      <MoreAboutSection />
      <StyledToast position='top-center' />
    </PageContainer>
  );
}

const StyledToast = styled(ToastContainer).attrs({
  // custom props
})`
  height: 30px;
  .Toastify__toast-container {
    height: 30px;
  }
  .Toastify__toast {
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    background-color: #07e2d4;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;
