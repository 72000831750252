import { useQuery } from 'react-query';
import axios from 'axios';
import { BASE_API_URL } from '../utils/app-constants';

export const useProduce = id => {
  return useQuery(
    ['products', id],
    async () => {
      const { data } = id
        ? await axios.get(`${BASE_API_URL}public/product?product_type_id=${id}`)
        : await axios.get(`${BASE_API_URL}public/product`);
      const { returned_resultset } = data;
      return returned_resultset;
    },
    {}
  );
};

export const useProductTypes = () => {
  return useQuery(
    'product-types',
    async () => {
      const { data } = await axios.get(`${BASE_API_URL}public/producttype`);
      const { returned_resultset } = data;
      return returned_resultset;
    },
    {}
  );
};
