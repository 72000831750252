import React from "react";
import styled, { css } from "styled-components";
import { ProductCard } from "../../components/productCard";
import { useProduce, useProductTypes } from "../../hooks/useProducts";
import { FaCartPlus, FaShoppingBasket, FaTrash } from "react-icons/fa";
import Logo from "../../assets/logo/logo2.png";
import { slide as Menu } from "react-burger-menu";
import { useState } from "react";
import { Button } from "../../components/button";
import { useCart } from "../../subjects/CartContext";
import { thousands_separators } from "../../utils/formatCurrency";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useForm } from "react-hook-form";

const getProductImage = name => {
  let image;
  switch (true) {
    case name.toLowerCase().includes("tilapia"):
      image = require("../../assets/pictures/Tilapia.jpeg");
      break;
    case name.toLowerCase().includes("nile"):
    case name.toLowerCase().includes("cat"):
      image = require("../../assets/pictures/NP-steak.jpeg");
      break;
    case name.toLowerCase().includes("fillet"):
      image = require("../../assets/pictures/Fillet.jpeg");
      break;
    case name.toLowerCase().includes("omena"):
      image = require("../../assets/pictures/omena.jpeg");
      break;
    case name.toLowerCase().includes("chicken"):
      image = require("../../assets/pictures/chicken.jpeg");
      break;
    default:
      image = require("../../assets/pictures/chicken.jpeg");
      break;
  }
  return image;
};

var styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    right: "36px",
    top: "15px",
    zIndex: 1000
  },
  bmBurgerBars: {
    background: "#fff"
  }
};

const Products = () => {
  const [selectedFilter, setSelectedFilter] = useState("");
  const { register, watch } = useForm();
  const filters = watch("product_type_id");
  const [isOpen, setIsOpen] = useState(false);
  const { data: products } = useProduce(selectedFilter);
  const { data: product_types } = useProductTypes();
  const { items, getTotalPrice, removeItem, getItemsCount } = useCart();

  const history = useHistory();

  return (
    <>
      <Container>
        <NavbarContainer>
          <LogoContainer onClick={() => history.push("/")}>
            <LogoImg src={Logo} />
          </LogoContainer>
          <BrandContainer onClick={() => setIsOpen(true)}>
            <FaCartPlus size={48} color="#8B8B8B" />
            <span>{getItemsCount()}</span>
          </BrandContainer>
        </NavbarContainer>
        <Header>
          <Title>{/* Our <span>Products</span> */}</Title>
        </Header>

        <ProductsContainer>
          <div>
            <strong>Filters</strong>
            <FiltersContainers>
              <Filter
                active={selectedFilter === ""}
                onClick={() => setSelectedFilter("")}
              >
                All
              </Filter>
              {product_types
                ?.filter(type => type.id !== 4)
                ?.map(type => (
                  <Tippy content={type?.description} key={type.id}>
                    <Filter
                      active={selectedFilter === type.id}
                      onClick={() => setSelectedFilter(type.id)}
                      key={type.id}
                    >
                      {type.name}
                    </Filter>
                  </Tippy>
                ))}
            </FiltersContainers>
          </div>

          <ProductList>
            {products
              ?.filter(
                prod =>
                  prod.id !== "66d580f5-6b81-49a3-bde0-7305c7c407fe" &&
                  prod.id !== "2d1c4fe1-4be1-4f36-bfb5-159a42deb61e" &&
                  !prod?.name.includes("Byproducts")
              )
              ?.map(item => {
                return (
                  <ProductCard
                    product={item}
                    key={item.id}
                    description={item?.description}
                    title={item.name}
                    price={item.unit_value}
                    backgroundColor="white"
                    itemInCart={items?.find(
                      prod => prod?.product.id === item.id
                    )}
                    // imgUrl={getProductImage(item.display_name)}
                    imgUrl={
                      item?.product_images?.length
                        ? item?.product_images[0]?.url
                        : getProductImage(item.display_name)
                    }
                  />
                );
              })}
          </ProductList>
        </ProductsContainer>
      </Container>
      <BurgerMenu
        styles={styles}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        // noOverlay
        isOpen={isOpen}
        outerContainerId={"outer-container"}
        customBurgerIcon={false}
        right
      >
        <div>
          <MenuHeader>
            <>
              <FaShoppingBasket size={32} color="#747577" />
              <p style={{ color: "#787878" }}>{getItemsCount()} items</p>
            </>
          </MenuHeader>
          {items?.map(item => {
            return (
              <CartItem key={item.product?.id} className="menu-item">
                <div>
                  <p style={{ fontWeight: "bold" }}>
                    {" "}
                    {item.product?.display_name}
                  </p>
                  <p>
                    {item?.qty} * {item.product?.unit_value}
                  </p>

                  <p>
                    Ksh.{" "}
                    {thousands_separators(item?.qty * item.product?.unit_value)}
                  </p>
                </div>
                <FaTrash
                  color="#8B8B8B"
                  onClick={() => removeItem(item.product?.id)}
                />
              </CartItem>
            );
          })}
          <CheckOutActions>
            <Button onClick={() => history.push("/checkout")}>
              <Totals>
                Checkout Now{" "}
                <span>
                  Total: Ksh.{" "}
                  {thousands_separators(
                    getTotalPrice() > 4000
                      ? getTotalPrice()
                      : getTotalPrice() + 400
                  )}
                </span>
              </Totals>
            </Button>
          </CheckOutActions>
        </div>
      </BurgerMenu>
    </>
  );
};

const Container = styled.div`
  display: grid;
  background-color: #f6f9fc;
  padding-bottom: 1rem;
`;

const FiltersContainers = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-evenly;
  align-items: center;
  margin: 1rem 0rem;
  column-gap: 1rem;
  row-gap: 1rem;
  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 11.5rem);
    column-gap: 1rem;
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(6, 11.5rem);
    column-gap: 1rem;
  }
`;

const Filter = styled.div`
  padding: 1rem;
  background-color: ${props => (props?.active ? "#494395" : "white")};
  color: ${props => (props?.active ? "white" : "#494395")};
  border-radius: 0.5rem;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
`;

const Title = styled.h3`
  font-size: 3.5rem;

  span {
    color: #494395;
  }
  @media screen and (max-width: 670px) {
    font-size: 1.5rem;
  }
`;

const ProductsContainer = styled.div`
  margin: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ProductList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 1rem;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
  }
`;
const NavbarContainer = styled.div`
  width: 100%;
  padding: 0.5rem 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  position: fixed;
  z-index: 10;
  color: black;
`;

const BrandContainer = styled.div`
  cursor: pointer;
  position: relative;
  span {
    background-color: red;
    color: white;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};
  align-items: center;
  @media screen and (max-width: 670px) {
    margin-top: 0.5rem;
  }
`;

const LogoImg = styled.img`
  width: 200px;
  height: 70px;

  @media screen and (max-width: 670px) {
    width: 100px;
    height: 40px;
  }

  ${({ inline }) =>
    inline &&
    css`
      width: 24px;
      height: 24px;
      margin-right: 6px;
    `};

  ${({ small }) =>
    small &&
    css`
      width: 8em;
      height: 8em;
    `};
`;

const MenuHeader = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  border-bottom: 1px solid #ccc;
  p {
    margin-left: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CheckOutActions = styled.div`
  display: flex;
  padding: 1em;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  Button {
    border-radius: 0.5rem;
    width: 100%;
    padding: 0;
  }
`;

const Totals = styled.p`
  font-weight: bold;
  width: 100%;
  padding: 0;
`;

const BurgerMenu = styled(Menu)`
  width: 25% !important;
  height: 100%;
  position: absolute;
  top: 0;
  .bm-menu {
    z-index: 10;
    background: #fff;
    box-shadow: -1px 0px 5px 1px #aaaaaa;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .bm-cross {
    background: #747577;
  }
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0;
  }
  .bm-item-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 0;
  }
  .menu-item {
    color: black;
    font-weight: normal;
    padding: 0;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 0 0.75rem;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    &:hover {
      background: rgba(73, 67, 149, 0.1);
    }
  }
  @media screen and (max-width: 670px) {
    width: 100% !important;
    height: 100% !important;
    .menu-item {
      font-size: 18px;
    }
  }
`;

export default Products;
