import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import styled from 'styled-components';

const CardContainer = styled.div`
  width: ${props => (props.small ? '24rem' : '26rem')};
  height: 32rem;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : 'rgba(73, 67, 149)'};

  margin: 5px 5px;
  position: relative;
  cursor: pointer;
  &:hover {
    img {
      transform: translateY(-10px);
    }
  }
  @media screen and (max-width: 670px) {
    width: ${props => (props.small ? '20rem' : '23rem')};
    height: 28rem;
  }
`;

const ReviewText = styled.p`
  font-size: 17px;
  color: white;
  font-weight: normal;
`;

const Line = styled.span`
  min-width: 100%;
  min-height: 1px;
  margin-bottom: 1em;
  background-color: #cdcdcd;
  display: flex;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 1em;

  span {
    color: white;
    font-style: normal;
    font-weight: bold;
  }
`;

const UserImg = styled.img`
  width: 100%;
  height: 70%;
  margin-right: 10px;

  transition: 0.5s ease-in-out;
`;

const Username = styled.h2`
  font-size: 25px;
  padding: 0;
  margin: 0;
  color: #fff;
`;

export function ReviewCard(props) {
  const { description, title, imgUrl, backgroundColor, small, details } = props;
  const history = useHistory();
  return (
    <CardContainer
      onClick={() => history.push('/products')}
      small={small}
      backgroundColor={backgroundColor}
    >
      <UserImg src={imgUrl} />
      <UserDetails>
        <Username>{title}</Username>
        <ReviewText>{description}</ReviewText>
        <span>{details}</span>
      </UserDetails>
    </CardContainer>
  );
}
