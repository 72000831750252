import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Element, scroller } from 'react-scroll';
import styled from 'styled-components';

import BackgroundImg from '../../assets/pictures/bg.jpg';
import { Button } from '../../components/button';
import { DownArrow } from '../../components/downArrow';
import { Logo } from '../../components/logo';
import { Marginer } from '../../components/marginer';
import { Navbar } from '../../components/navbar';

const TopContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0;
  background-image: url(${BackgroundImg});
  position: relative;
`;

const BackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(73, 67, 149, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MotivationalTextTitle = styled.h1`
  font-size: 62px;
  font-style: italic;
  font-weight: 700;
  line-height: 1.4;
  color: #fff;
  margin: 0;
  text-align: center;
  @media screen and (max-width: 670px) {
    font-size: 28px;
  }
`;

const MotivationalText = styled.h1`
  font-size: 28px;
  font-weight: 400;
  line-height: 1.4;
  color: #fff;
  margin: 0;
  text-align: center;
  @media screen and (max-width: 670px) {
    font-size: 18px;
  }
`;

const DownArrowContainer = styled.div`
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 670px) {
    bottom: 10px;
  }
`;

export function TopSection(props) {
  const scrollToNextSection = () => {
    scroller.scrollTo('buy', { smooth: true, duration: 1500 });
  };

  const history = useHistory();

  return (
    <Element name='home'>
      <TopContainer>
        <BackgroundFilter>
          <Navbar />
          <Marginer direction='vertical' margin='8em' />
          <Marginer direction='vertical' margin='8em' />
          <Marginer direction='vertical' margin='8em' />

          <MotivationalTextTitle>
            From the lake to your plate
          </MotivationalTextTitle>
          <MotivationalText>
            We deliver fresh fish to your business or homes at very affordable
            prices.
          </MotivationalText>
          <MotivationalText>
            Tunauza samaki freshi kutoka Ziwa Victoria kwa bei nafuu.
          </MotivationalText>
          <Marginer direction='vertical' margin='2em' />

          <DownArrowContainer onClick={() => history.push('/products')}>
            <DownArrow />
          </DownArrowContainer>
        </BackgroundFilter>
      </TopContainer>
    </Element>
  );
}
