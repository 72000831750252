import React from 'react';
import './App.css';
import { Homepage } from './containers/homepage';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import PrivacyPolicy from './containers/privacyPolicy';
import TermsAndConditions from './containers/TermsAndConditions';
import Products from './containers/products/Products';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CartProvider } from './subjects/CartContext';
import Checkout from './containers/products/Checkout';
const queryClient = new QueryClient();
function App(props) {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <CartProvider>
          <Switch>
            <Route exact path='/privacy-policy'>
              <PrivacyPolicy />
            </Route>
            <Route exact path='/terms-and-conditions'>
              <TermsAndConditions />
            </Route>
            <Route path='/products'>
              <Products />
            </Route>
            <Route path='/checkout'>
              <Checkout />
            </Route>
            <Route path='/'>
              <Homepage />
            </Route>
          </Switch>
        </CartProvider>
      </QueryClientProvider>
      <ToastContainer position='top-center' />
    </Router>
  );
}

export default App;
