import React, { useEffect, useRef } from 'react';
import { Element, Events } from 'react-scroll';
import styled from 'styled-components';
import { Marginer } from '../../components/marginer';
import { OurSerivce } from '../../components/ourService';
import { SectionTitle } from '../../components/sectionTitle';
import BackgroundImg from '../../assets/pictures/back_white.png';
import { ReviewCard } from '../../components/reviewCard';
import ColdContainer from '../../assets/pictures/fridge.jpg';
import Nile from '../../assets/pictures/NP-steak.jpeg';
import Fillet from '../../assets/pictures/Fillet.jpeg';
import Tilapia from '../../assets/pictures/Tilapia.jpeg';
import Chicken from '../../assets/pictures/chicken.jpeg';
import Omena from '../../assets/pictures/omena.jpeg';
import { Button } from '../../components/button';
import { NavbarSubject } from '../../subjects/NavbarSubject';
import { useHistory } from 'react-router-dom';

const ServicesContainer = styled(Element)`
  width: 100%;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  background-image: url(${BackgroundImg});
  align-items: center;
  padding: 10px 1rem;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  justify-items: center;
  gap: 0.5rem;
  padding: 0;
  @media screen and (max-width: 1500px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, minmax(300px, 1fr));
  }
  @media screen and (max-width: 670px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, minmax(300px, 1fr));
  }
`;

export function ServicesSection(props) {
  const elementRef = useRef(null);
  const history = useHistory();
  useEffect(() => {
    // Events.scrollEvent.register('begin', function (to, element) {
    //   if (to === 'servicesSection') {
    //     NavbarSubject.next({ color: 'rgba(73, 67, 149)' });
    //   }
    // });
    console.log(elementRef.current);
  }, [elementRef]);

  return (
    <ServicesContainer ref={elementRef} name='buy'>
      <Marginer direction='vertical' margin='3em' />

      <SectionTitle>We deliver while still fresh</SectionTitle>
      <Marginer direction='vertical' margin='4em' />
      <CardsContainer>
        <ReviewCard
          description='Gutted, scaled and ready to cook.'
          title=' Tilapia (Ngege)'
          details='Whole'
          imgUrl={Tilapia}
          backgroundColor='#2AB4AA'
          small
        />

        <ReviewCard
          description='Well packaged Nile Perch and Tilapia fillets '
          details='Per Kg'
          title='Fillets'
          imgUrl={Fillet}
          backgroundColor='#2AB4AA'
          small
        />
        <ReviewCard
          description='Dried using our clean solar driers.'
          details='Per kg'
          title='Omena/Dagaa'
          imgUrl={Omena}
          backgroundColor='#2AB4AA'
          small
        />
        <ReviewCard
          description='Locally sourced from farmers (halal).'
          details='Per kg'
          title='Chicken'
          imgUrl={Chicken}
          backgroundColor='#2AB4AA'
          small
        />
      </CardsContainer>
      <Marginer direction='vertical' margin='5em' />
      <Button onClick={() => history.push('/products')}>
        SEE MORE PRODUCTS
      </Button>
    </ServicesContainer>
  );
}
