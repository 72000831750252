import React from 'react';
import { Element } from 'react-scroll';
import styled from 'styled-components';
import BackgroundImg from '../../assets/pictures/back_purple.png';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { theme } from '../../theme';
import StoreBadge from 'react-store-badge';
import { init, sendForm } from 'emailjs-com';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const MoreAboutContainer = styled(Element)`
  min-height: 500px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-image: url(${BackgroundImg});
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  padding: 0 1em;
  h5 {
    font-size: 14px;
    line-height: 1.4;
    font-weight: normal;
    width: 20rem;
    color: white;
    margin-top: 3em;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
`;

const AboutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 45vh;

  @media screen and (max-width: 670px) {
    width: 100%;
    height: 50vh;
    margin-top: 1rem;
  }
`;

const AboutText = styled.p`
  font-size: 21px;
  line-height: 1.4;
  color: white;
  padding: 0;
  font-weight: bold;
`;

const DetailsSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 80%;
  @media screen and (max-width: 670px) {
    display: flex;
    max-width: 100%;
    flex-direction: column;
  }
`;
const Location = styled.div`
  margin-top: 2em;
`;

const Contact = styled.div`
  display: block;
  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      color: white;
      font-weight: normal;
      font-size: 14px;
      span {
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  input {
    appearance: none;
    margin-bottom: 1rem;
    background: transparent;
    border: none;
    border-bottom: 2px dashed white;
    color: white;
    padding: 1rem 0rem;
    &:focus {
      outline: none;
    }
    ::placeholder {
      color: whitesmoke;
    }
  }
  textarea {
    appearance: none;
    background: transparent;
    border: none;
    border-bottom: 2px dashed white;
    color: white;
    font-style: none;
    padding: 1rem 0rem;
    &:focus {
      outline: none;
    }
    ::placeholder {
      color: whitesmoke;
    }
  }
`;

const ContactButton = styled.button`
  padding: 0.5em 1em;
  border-radius: 1px;
  margin-top: 1rem;
  width: 30%;
  margin-left: auto;
  background-color: transparent;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  outline: none;
  border: 2px solid white;
  transition: all 220ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${theme.primary};
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 0;
  padding: 0;
  margin-bottom: 0.2em;
`;

const Playstore = styled.div`
  margin: 0;
  img {
    width: 60%;
    height: 15rem;

    padding: 0;
    margin: 0;
    vertical-align: top;
  }
`;

const containerStyle = {
  width: '100%',
  height: '100%'
};

init('user_Q1UjiMTtd9FAM5Us9Zo7C');

export function MoreAboutSection(props) {
  const [map, setMap] = React.useState(null);
  const { register, handleSubmit, watch, formState, reset } = useForm();
  const { errors } = formState;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API
  });

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  const center = {
    lat: -1.2110875,
    lng: 36.794739
  };

  const handleAddData = data => {
    sendForm('contact_form', 'contact_message', '#contact-form').then(
      function (response) {
        toast.success('Thank you! Your message has been received');
        reset();
      },
      function (error) {
        toast.error('Unable to send message! Please try again');
      }
    );
  };

  return (
    <MoreAboutContainer name='contact'>
      <AboutContainer>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
            onLoad={onLoad}
            id='google-map-script'
            onUnmount={onUnmount}
          >
            {/* Child components, such as markers, info windows, etc. */}
            <></>
          </GoogleMap>
        ) : (
          <></>
        )}
      </AboutContainer>

      <DetailsSection>
        <Location>
          <StoreBadge
            name='Cheerswipe'
            googlePlayUrl='https://play.google.com/store/apps/details?id=com.markiti'
          />

          <h5>
            1st Floor, Southern Tower - Two Rivers Lifestyle Center - Limuru
            road, Rosyln, Nairobi.
          </h5>
        </Location>
        <Contact>
          <AboutText>Contact us</AboutText>
          <ul>
            <li>
              <span>Tel</span> +254 20 2400740
            </li>
            <li>
              <span>Tel</span> +254 716 888877
            </li>
          </ul>
          <Contact>
            <AboutText>Email</AboutText>
            <ul>
              <li>supply@markiti.co.ke</li>
              <li>support@markiti.co.ke</li>
            </ul>
          </Contact>
        </Contact>
        <ContactForm id='contact-form' onSubmit={handleSubmit(handleAddData)}>
          <input
            type='email'
            name='user_email'
            placeholder='Email'
            {...register('user_email', {
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Enter a valid e-mail address'
              }
            })}
          />
          {errors?.user_email && (
            <ErrorMessage>{errors.user_email.message}</ErrorMessage>
          )}
          <input
            type='text'
            name='contact_number'
            placeholder='Phone'
            {...register('contact_number', {
              required: 'Phone Number is required'
            })}
          />
          {errors?.contact_number && (
            <ErrorMessage>{errors.contact_number.message}</ErrorMessage>
          )}
          <textarea
            type='text'
            name='message'
            {...register('message', {
              required: 'Please provide a message'
            })}
            placeholder='Message'
          />
          {errors?.message && (
            <ErrorMessage>{errors.message.message}</ErrorMessage>
          )}
          <ContactButton>Send</ContactButton>
        </ContactForm>
      </DetailsSection>
    </MoreAboutContainer>
  );
}
