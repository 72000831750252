import { CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel';
import React, { useState } from 'react';
import { Element } from 'react-scroll';
import styled from 'styled-components';
import { Marginer } from '../../components/marginer';
import { ReviewCard } from '../../components/reviewCard';
import { SectionTitle } from '../../components/sectionTitle';
import { useMediaQuery } from 'react-responsive';
import BackgroundImg from '../../assets/pictures/back_green.png';

import 'pure-react-carousel/dist/react-carousel.es.css';

import ColdContainer from '../../assets/pictures/coolbox.png';
import Truck from '../../assets/pictures/truck.png';
import DetailsImage from '../../assets/pictures/image-011.jpg';
import { Button } from '../../components/button';
import ModalComponent from '../../components/modal';

const Container = styled(Element)`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url(${BackgroundImg});
  align-items: center;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 2rem;
  padding: 0;
  @media screen and (max-width: 1500px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, minmax(300px, 1fr));
  }
  @media screen and (max-width: 670px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, minmax(300px, 1fr));
  }
`;

const CardBackground = styled.div`
  width: 26rem;
  height: 32rem;
  background-image: url(${DetailsImage});
  margin: 5px 5px;
  @media screen and (max-width: 670px) {
    width: 23rem;
    height: 28rem;
  }
`;

const BackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  padding-top: 6rem;
  padding-bottom: 4rem;
  position: relative;
  p {
    padding: 1rem;
    color: white;
    font-size: 24px;
    font-style: none;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  Button {
    background-color: #2ab4aa;
    margin-top: auto;
  }
  &:hover {
    img {
      transform: translateY(-10px);
    }
  }
`;

export function ReviewsSection(props) {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container name='wholesale'>
      {/* <SectionTitle>What others are saying about us</SectionTitle> */}
      <Marginer direction='vertical' margin='10em' />

      <CardsContainer>
        <CardBackground>
          <BackgroundFilter>
            <p>
              We partner with our distributors/ resellers to improve their shelf
              life of the fish by providing free refrigeration services.
            </p>
            <p>We deliver to you at your shop at no cost to you.</p>
            <Button onClick={() => setIsOpen(true)}>
              EXPRESS YOUR INTEREST
            </Button>
          </BackgroundFilter>
        </CardBackground>

        <ReviewCard
          title='Cold Storage'
          description='Cooling as a service for as low as Kshs. 500'
          imgUrl={ColdContainer}
        />
        <ReviewCard
          title='Cold Logistics'
          description='Proper Handling, coldchain Logistics'
          imgUrl={Truck}
        />
      </CardsContainer>
      <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} />
    </Container>
  );
}
